import { PAGE_BEFORE_INTRODUCTION } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/config/urls'
import { useIntroduction } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/IntroductionContext'
import { useRoutesGraph } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/context/RoutesGraphContext'
import { getProgress } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/lib/getProgress/getProgress'
import { StepsTemplate } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/StepsTemplate'
import { Sentences } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/Sentences/Sentences'
import { Title } from '@pretto/app/src/Application/Containers/ApplicationIntroductionForm/templates/StepsTemplate/components/Title/Title'
import { Choices } from '@pretto/app/src/Sentences/components/Styled/Choices/Choices'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { useTracking } from '@pretto/app/src/lib/tracking'

import { isNil } from 'lodash'
import { useState } from 'react'
import { useHistory } from 'react-router'

export const OverdraftStep: React.FC = () => {
  const { comortgagorHasOverdraft, hasComortgagor, hasOverdraft, maturity, setContext } = useIntroduction()
  const { goToNextRoute, goToPreviousRoute } = useRoutesGraph()
  const { push } = useHistory()
  const trackAction = useTracking()

  const nextRouteCondition = hasComortgagor
    ? !isNil(hasOverdraft) && !isNil(comortgagorHasOverdraft)
    : !isNil(hasOverdraft)

  const [activeField, setActiveField] = useState<number | null>(nextRouteCondition ? null : 0)

  const handleClose = () => {
    trackAction('APPLICATION_INTRODUCTION_CLOSED')
    push(PAGE_BEFORE_INTRODUCTION)
  }

  const handleNext = () => {
    trackAction('APPLICATION_INTRODUCTION_NEXT_BUTTON_CLICKED')
    goToNextRoute()
  }

  const choicesHasOverdraft = [
    {
      key: 'hasOverdraft',
      isSelected: hasOverdraft === true,
      onClick: () => {
        setContext({ hasOverdraft: true })

        if (hasComortgagor) {
          setActiveField(1)
          return
        }
        setActiveField(null)

        goToNextRoute()
      },
      label: `Vous avez`,
    },
    {
      key: 'hasNoOverdraft',
      isSelected: hasOverdraft === false,
      onClick: () => {
        setContext({ hasOverdraft: false })

        if (hasComortgagor) {
          setActiveField(1)
          return
        }
        setActiveField(null)

        goToNextRoute()
      },
      label: `Vous n'avez pas`,
    },
  ]

  const choicesComortgagorHasOverdraft = [
    {
      key: 'comortgagorHasOverdraft',
      isSelected: comortgagorHasOverdraft === true,
      onClick: () => {
        setContext({ comortgagorHasOverdraft: true })
        setActiveField(null)
        goToNextRoute()
      },
      label: `A`,
    },
    {
      key: 'comortgagorHasNoOverdraft',
      isSelected: comortgagorHasOverdraft === false,
      onClick: () => {
        setContext({ comortgagorHasOverdraft: false })
        setActiveField(null)
        goToNextRoute()
      },
      label: `N'a pas`,
    },
  ]

  const choicesOrder = [choicesHasOverdraft, choicesComortgagorHasOverdraft]

  return (
    <StepsTemplate
      onClose={handleClose}
      onNext={nextRouteCondition ? handleNext : undefined}
      previousStep={() => goToPreviousRoute()}
      progress={getProgress({ hasComortgagor, maturity })}
    >
      <Title>Votre situation bancaire</Title>

      <Sentences>
        <Prompt
          onClick={() => {
            setActiveField(0)
          }}
          isFilled={!isNil(hasOverdraft)}
        >
          {choicesHasOverdraft[hasOverdraft ? 0 : 1].label}
        </Prompt>
        été à découvert au cours des 3 derniers mois.
        {hasComortgagor && (
          <span>
            &nbsp;Mon co-emprunteur
            <Prompt
              onClick={() => {
                setActiveField(1)
              }}
              isFilled={!isNil(comortgagorHasOverdraft)}
            >
              {choicesComortgagorHasOverdraft[comortgagorHasOverdraft ? 0 : 1].label.toLocaleLowerCase()}
            </Prompt>
            été à découvert au cours des 3 derniers mois.
          </span>
        )}
      </Sentences>

      {activeField !== null && <Choices withoutTracking choices={choicesOrder[activeField]} />}
    </StepsTemplate>
  )
}
