import { checkSeniorityYearIsValid } from '@pretto/app/src/Sentences/Occupation/Sector/lib/checkSeniorityYearIsValid'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { intToMonth } from '@pretto/app/src/Sentences/lib/intToMonth'
import { getJobStartYearPlaceholder } from '@pretto/app/src/Sentences/lib/placeholders'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { YesNo } from '@pretto/app/src/Sentences/types/enums'
import { t } from '@pretto/app/src/lib/i18n'
import {
  SimulationMortgagorOccupationDuration,
  SimulationMortgagorOccupationPrivateRole,
} from '@pretto/app/src/types/api/enums'

import isNil from 'lodash/isNil'
import { useDebounce } from 'use-debounce'

interface PrivateSentenceProps {
  activeField: number
  onSetActive: (field: number) => void
}

export const PrivateSentence: React.FC<PrivateSentenceProps> = ({ activeField, onSetActive }) => {
  const { simulation, setSimulationMortgagor } = useSentences()
  const mainMortgagor = simulation.profileMortgagors[0] ?? {}
  const [debouncedStartYear] = useDebounce(mainMortgagor.occupationStartYear, 1000)

  const handleYearChange = (value: string) => {
    setSimulationMortgagor({ occupationStartYear: toIntOrNull(value) })
  }

  return (
    <>
      {t('sentences.situation.in')}
      <Prompt
        onClick={() => {
          onSetActive(1)
        }}
        isFilled={!!mainMortgagor.occupationDuration}
      >
        {t(
          `sentences.situation.contractType.values.${
            mainMortgagor.occupationDuration || SimulationMortgagorOccupationDuration.LongTerm
          }`
        )}
      </Prompt>
      {(activeField > 1 || !isNil(mainMortgagor.occupationPrivateRole)) &&
        mainMortgagor.occupationDuration === SimulationMortgagorOccupationDuration.LongTerm && (
          <>
            <Prompt
              onClick={() => {
                onSetActive(2)
              }}
              isFilled={!isNil(mainMortgagor.occupationPrivateRole)}
            >
              {t(
                `sentences.situation.private.longTerm.executive.values.${
                  mainMortgagor.occupationPrivateRole === SimulationMortgagorOccupationPrivateRole.Executive
                    ? SimulationMortgagorOccupationPrivateRole.Executive
                    : SimulationMortgagorOccupationPrivateRole.NonExecutive
                }`
              )}
            </Prompt>
          </>
        )}
      {(activeField > 2 || !isNil(mainMortgagor.occupationTrialPeriod)) &&
        mainMortgagor.occupationDuration === SimulationMortgagorOccupationDuration.LongTerm && (
          <>
            <Prompt
              onClick={() => {
                onSetActive(3)
              }}
              isFilled={!isNil(mainMortgagor.occupationTrialPeriod)}
            >
              {t(
                `sentences.situation.private.longTerm.trial.values.${
                  mainMortgagor.occupationTrialPeriod || isNil(mainMortgagor.occupationTrialPeriod)
                    ? YesNo.Yes
                    : YesNo.No
                }`
              )}
            </Prompt>
          </>
        )}
      {(activeField > 1 || mainMortgagor.occupationStartMonth) &&
        mainMortgagor.occupationDuration === SimulationMortgagorOccupationDuration.ShortTerm && (
          <>
            {t('sentences.situation.since')}
            <Prompt
              isFilled={!isNil(mainMortgagor.occupationStartMonth)}
              onClick={() => {
                onSetActive(2)
              }}
            >
              {t(`sentences.months.${intToMonth(mainMortgagor.occupationStartMonth ?? 0)}`)}
            </Prompt>
            <TrackedResizableField
              clearable
              events={{
                eventName: EventName.SimulationFieldChanged,
                eventPayload: { field: 'occupationSectorPrivateSince' },
              }}
              inputProps={{ format: '0000', placeholder: getJobStartYearPlaceholder(), type: 'date' }}
              message={t('sentences.situation.error')}
              onChange={handleYearChange}
              state={checkSeniorityYearIsValid(debouncedStartYear) || !debouncedStartYear ? 'default' : 'error'}
              value={mainMortgagor.occupationStartYear?.toString() || ''}
            />
          </>
        )}
    </>
  )
}
