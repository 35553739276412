import { checkSeniorityYearIsValid } from '@pretto/app/src/Sentences/Occupation/Sector/lib/checkSeniorityYearIsValid'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { intToMonth } from '@pretto/app/src/Sentences/lib/intToMonth'
import { getJobStartYearPlaceholder } from '@pretto/app/src/Sentences/lib/placeholders'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { t } from '@pretto/app/src/lib/i18n'
import {
  SimulationMortgagorOccupationPublicStatus,
  SimulationMortgagorOccupationDuration,
} from '@pretto/app/src/types/api/enums'

import isNil from 'lodash/isNil'
import { useDebounce } from 'use-debounce'

interface PublicSentenceProps {
  activeField: number
  onSetActive: (field: number) => void
}

export const PublicSentence: React.FC<PublicSentenceProps> = ({ activeField, onSetActive }) => {
  const { simulation, setSimulationMortgagor } = useSentences()
  const mainMortgagor = simulation.profileMortgagors[0] ?? {}
  const [debouncedStartYear] = useDebounce(mainMortgagor.occupationStartYear, 1000)

  const handleYearChange = (value: string) => {
    setSimulationMortgagor({ occupationStartYear: toIntOrNull(value) })
  }

  return (
    <>
      {t('sentences.situation.public.sentence')}
      <Prompt
        onClick={() => {
          onSetActive(1)
        }}
        isFilled={!!mainMortgagor.occupationPublicStatus}
      >
        {t(
          `sentences.situation.public.values.${
            (mainMortgagor.occupationPublicStatus as SimulationMortgagorOccupationPublicStatus) ||
            SimulationMortgagorOccupationPublicStatus.Tenure
          }`
        )}
      </Prompt>
      {activeField > 1 &&
        mainMortgagor.occupationPublicStatus === SimulationMortgagorOccupationPublicStatus.Contractor && (
          <>
            {t('sentences.situation.in')}
            <Prompt
              onClick={() => {
                onSetActive(2)
              }}
              isFilled={!!mainMortgagor.occupationDuration}
            >
              {t(
                `sentences.situation.contractType.values.${
                  mainMortgagor.occupationDuration || SimulationMortgagorOccupationDuration.LongTerm
                }`
              )}
            </Prompt>
          </>
        )}
      {activeField > 2 &&
        mainMortgagor.occupationPublicStatus === SimulationMortgagorOccupationPublicStatus.Contractor &&
        mainMortgagor.occupationDuration === SimulationMortgagorOccupationDuration.ShortTerm && (
          <>
            {t('sentences.situation.since')}
            <Prompt
              isFilled={!isNil(mainMortgagor.occupationStartMonth)}
              onClick={() => {
                onSetActive(3)
              }}
            >
              {t(`sentences.months.${intToMonth(mainMortgagor.occupationStartMonth ?? 0)}`)}
            </Prompt>
            <TrackedResizableField
              clearable
              events={{
                eventName: EventName.SimulationFieldChanged,
                eventPayload: { field: 'occupationSectorPublicSince' },
              }}
              inputProps={{ format: '0000', placeholder: getJobStartYearPlaceholder(), type: 'date' }}
              message={t('sentences.situation.error')}
              onChange={handleYearChange}
              state={checkSeniorityYearIsValid(debouncedStartYear) || !debouncedStartYear ? 'default' : 'error'}
              value={mainMortgagor.occupationStartYear?.toString() || ''}
            />
          </>
        )}
    </>
  )
}
