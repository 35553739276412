import { checkSeniorityYearIsValid } from '@pretto/app/src/Sentences/Occupation/Sector/lib/checkSeniorityYearIsValid'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { intToMonth } from '@pretto/app/src/Sentences/lib/intToMonth'
import { getJobStartYearPlaceholder } from '@pretto/app/src/Sentences/lib/placeholders'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { t } from '@pretto/app/src/lib/i18n'
import {
  SimulationMortgagorOccupationKind,
  SimulationMortgagorOccupationOtherStatus,
} from '@pretto/app/src/types/api/enums'

import isNil from 'lodash/isNil'
import { useDebounce } from 'use-debounce'

interface OthersSentenceProps {
  activeField: number
  onSetActive: (field: number) => void
}

export const OthersSentence: React.FC<OthersSentenceProps> = ({ activeField, onSetActive }) => {
  const { simulation, setSimulationMortgagor } = useSentences()
  const mainMortgagor = simulation.profileMortgagors[0] ?? {}
  const [debouncedStartYear] = useDebounce(mainMortgagor.occupationStartYear, 1000)

  const handleYearChange = (value: string) => {
    setSimulationMortgagor({ occupationStartYear: toIntOrNull(value) })
  }

  return (
    <>
      {mainMortgagor.occupationKind !== SimulationMortgagorOccupationKind.Medical && (
        <>
          {t('sentences.situation.others.sentence')}
          <Prompt
            onClick={() => {
              onSetActive(1)
            }}
            isFilled={!!mainMortgagor.occupationOtherStatus}
          >
            {t(
              `sentences.situation.others.values.${
                (mainMortgagor.occupationOtherStatus as SimulationMortgagorOccupationOtherStatus) ||
                SimulationMortgagorOccupationOtherStatus.SelfEmployed
              }`
            )}
          </Prompt>
        </>
      )}
      {((activeField > 1 &&
        mainMortgagor.occupationOtherStatus !== SimulationMortgagorOccupationOtherStatus.Retired &&
        mainMortgagor.occupationOtherStatus !== SimulationMortgagorOccupationOtherStatus.Jobless) ||
        (activeField === 1 && mainMortgagor.occupationKind === SimulationMortgagorOccupationKind.Medical)) && (
        <>
          {t('sentences.situation.since')}
          <Prompt onClick={() => onSetActive(2)} isFilled={!isNil(mainMortgagor.occupationStartMonth)}>
            {t(`sentences.months.${intToMonth(mainMortgagor.occupationStartMonth ?? 0)}`)}
          </Prompt>
          <TrackedResizableField
            clearable
            events={{
              eventName: EventName.SimulationFieldChanged,
              eventPayload: { field: 'occupationSectorOthersSince' },
            }}
            inputProps={{ format: '0000', placeholder: getJobStartYearPlaceholder(), type: 'date' }}
            message={t('sentences.situation.error')}
            onChange={handleYearChange}
            state={checkSeniorityYearIsValid(debouncedStartYear) || !debouncedStartYear ? 'default' : 'error'}
            value={mainMortgagor.occupationStartYear?.toString() || ''}
          />
        </>
      )}
    </>
  )
}
