import { checkSeniorityYearIsValid } from '@pretto/app/src/Sentences/Occupation/Sector/lib/checkSeniorityYearIsValid'
import { Prompt } from '@pretto/app/src/Sentences/components/Styled/Prompt/Prompt'
import { EventName } from '@pretto/app/src/Sentences/config/tracking/events'
import { TrackedResizableField } from '@pretto/app/src/Sentences/containers/TrackedResizableField/TrackedResizableField'
import { useSentences } from '@pretto/app/src/Sentences/contexts/SentencesContext'
import { intToMonth } from '@pretto/app/src/Sentences/lib/intToMonth'
import { getJobStartYearPlaceholder } from '@pretto/app/src/Sentences/lib/placeholders'
import { toIntOrNull } from '@pretto/app/src/Sentences/lib/toIntOrNull'
import { t } from '@pretto/app/src/lib/i18n'
import {
  SimulationMortgagorOccupationDuration,
  SimulationMortgagorOccupationPublicStatus,
} from '@pretto/app/src/types/api/enums'

import { isNil } from 'lodash'
import { useDebounce } from 'use-debounce'

interface PublicSentenceProps {
  activeField: number
  onSetActive: (field: number) => void
}

export const PublicSentence: React.FC<PublicSentenceProps> = ({ activeField, onSetActive }) => {
  const { simulation, setSimulationComortgagor } = useSentences()

  const [debouncedStartYear] = useDebounce(simulation.profileMortgagors[1]?.occupationStartYear, 1000)

  if (!simulation.profileMortgagors[1]) {
    return null
  }

  const handleYearChange = (value: string) => {
    setSimulationComortgagor({ occupationStartYear: toIntOrNull(value) })
  }

  return (
    <>
      {t('sentences.situation.public.sentence')}
      <Prompt
        onClick={() => {
          onSetActive(1)
        }}
        isFilled={!!simulation.profileMortgagors[1].occupationPublicStatus}
      >
        {t(
          `sentences.situation.public.values.${
            simulation.profileMortgagors[1].occupationPublicStatus || SimulationMortgagorOccupationPublicStatus.Tenure
          }`
        )}
      </Prompt>
      {(activeField > 1 ||
        simulation.profileMortgagors[1].occupationStartMonth ||
        simulation.profileMortgagors[1].occupationStartYear) &&
        simulation.profileMortgagors[1].occupationPublicStatus ===
          SimulationMortgagorOccupationPublicStatus.Contractor && (
          <>
            {t('sentences.situation.in')}
            <Prompt
              onClick={() => {
                onSetActive(2)
              }}
              isFilled={!!simulation.profileMortgagors[1].occupationDuration}
            >
              {t(
                `sentences.situation.contractType.values.${
                  simulation.profileMortgagors[1].occupationDuration || SimulationMortgagorOccupationDuration.LongTerm
                }`
              )}
            </Prompt>
          </>
        )}
      {(activeField > 2 ||
        simulation.profileMortgagors[1].occupationStartMonth ||
        simulation.profileMortgagors[1].occupationStartYear) &&
        simulation.profileMortgagors[1].occupationPublicStatus ===
          SimulationMortgagorOccupationPublicStatus.Contractor &&
        simulation.profileMortgagors[1].occupationDuration === SimulationMortgagorOccupationDuration.ShortTerm && (
          <>
            {t('sentences.situation.since')}
            <Prompt
              isFilled={!isNil(simulation.profileMortgagors[1].occupationStartMonth)}
              onClick={() => {
                onSetActive(3)
              }}
            >
              {t(`sentences.months.${intToMonth(simulation.profileMortgagors[1].occupationStartMonth ?? 0)}`)}
            </Prompt>
            <TrackedResizableField
              clearable
              events={{
                eventName: EventName.SimulationFieldChanged,
                eventPayload: { field: 'coMortgagorOccupationPublicSince' },
              }}
              inputProps={{ format: '0000', placeholder: getJobStartYearPlaceholder(), type: 'date' }}
              message={t('sentences.situation.error')}
              onChange={handleYearChange}
              state={checkSeniorityYearIsValid(debouncedStartYear) || !debouncedStartYear ? 'default' : 'error'}
              value={simulation.profileMortgagors[1].occupationStartYear?.toString() || ''}
            />
          </>
        )}
    </>
  )
}
