import { Frequency } from '@pretto/app/src/Sentences/types/enums'
import type { ContextValidator } from '@pretto/app/src/Sentences/types/validators'
import { getUtmFlagsLastClicked } from '@pretto/app/src/lib/helpers'
import { isPartner as isProjectChoicePartner } from '@pretto/app/src/projectChoice/lib/isPartner'
import {
  SimulationKind,
  SimulationMaturityStatus,
  SimulationMortgagorLodgingLocationKind,
  SimulationMortgagorLodgingSituationKind,
  SimulationMortgagorOccupationDuration,
  SimulationMortgagorOccupationKind,
  SimulationMortgagorOccupationOtherStatus,
  SimulationMortgagorOccupationPublicStatus,
  SimulationPropertyKind,
  SimulationPropertyUsage,
  SimulationRenegotiationLoanInsuranceKind,
  SimulationStructureKind,
  SimulationStructureSciAssociation,
  SimulationStructureSciKind,
} from '@pretto/app/src/types/api/enums'
import { SimulationMortgagor } from '@pretto/app/src/types/api/schema'

import allPass from 'ramda/src/allPass'
import anyPass from 'ramda/src/anyPass'
import isNil from 'ramda/src/isNil'

import excludedPtzZipCodes from './excludedPtzZipCodes'

const isNotNil = (value: unknown) => !isNil(value)

export const isProspect: ContextValidator = ({ isLoggedIn }) => isLoggedIn !== true

export const isLoggedIn: ContextValidator = ({ isLoggedIn }) => isLoggedIn === true

export const hasMortgagor: ContextValidator = ({ simulation }) => isNotNil(simulation.profileMortgagors[0])

export const hasComortgagor: ContextValidator = ({ simulation }) => isNotNil(simulation.profileMortgagors[1])

export const isBuyout: ContextValidator = ({ simulation }) => simulation.kind === SimulationKind.Buyout

export const isRenegotiation: ContextValidator = ({ simulation }) => simulation.kind === SimulationKind.Renegotiation

export const isNotRenegotiation: ContextValidator = ({ simulation }) => simulation.kind !== SimulationKind.Renegotiation

export const hasSimulationType: ContextValidator = ({ simulation }) => isNotNil(simulation.kind)

export const hasMaturityType: ContextValidator = ({ simulation }) => isNotNil(simulation.maturityStatus)

export const hasMaturityPropertyPrice: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.propertyHasPropertyPrice)

export const isMaturityTypeUnderStudy: ContextValidator = ({ simulation }) =>
  simulation.maturityStatus === SimulationMaturityStatus.UnderStudy

export const isMaturityTypeSearchStarted: ContextValidator = ({ simulation }) =>
  simulation.maturityStatus === SimulationMaturityStatus.SearchStarted

export const isMaturityTypeOfferMade: ContextValidator = ({ simulation }) =>
  simulation.maturityStatus === SimulationMaturityStatus.OfferMade

export const isMaturityTypeOfferAccepted: ContextValidator = ({ simulation }) =>
  simulation.maturityStatus === SimulationMaturityStatus.OfferAccepted

export const isMaturityTypeSaleAgreementSigned: ContextValidator = ({ simulation }) =>
  simulation.maturityStatus === SimulationMaturityStatus.SaleAgreementSigned

export const isMaturityComplete: ContextValidator = context =>
  hasMaturityType(context) &&
  (!isMaturityTypeSaleAgreementSigned(context) ||
    (isMaturityTypeSaleAgreementSigned(context) && hasAgreementSignedDate(context)))

export const hasAgreementSignedDate: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.maturityAgreementSignedDate)

export const hasStructure: ContextValidator = ({ simulation }) => isNotNil(simulation.structureKind)

export const isSCI: ContextValidator = ({ simulation }) => simulation.structureKind === SimulationStructureKind.Sci

export const isNotSCI: ContextValidator = ({ simulation }) => simulation.structureKind !== SimulationStructureKind.Sci

export const isIR: ContextValidator = ({ simulation }) => simulation.structureSciKind === SimulationStructureSciKind.Ir

export const hasPropertyUsage: ContextValidator = ({ simulation }) => isNotNil(simulation.propertyUsage)

export const hasPropertyType: ContextValidator = ({ simulation }) => isNotNil(simulation.propertyKind)

export const isRentalUsage: ContextValidator = ({ simulation }) =>
  simulation.propertyUsage === SimulationPropertyUsage.RentalInvestment

export const isNotRentalUsage: ContextValidator = ({ simulation }) =>
  simulation.propertyUsage !== SimulationPropertyUsage.RentalInvestment

export const isExpectedRentalIncomeFilled: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.propertyExpectedRent)

export const isSolo: ContextValidator = ({ simulation }) => simulation.structureKind === SimulationStructureKind.Solo

export const isDuo: ContextValidator = ({ simulation }) => simulation.structureKind === SimulationStructureKind.Duo

export const isNotSolo: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.structureKind) && simulation.structureKind !== SimulationStructureKind.Solo

export const isAssociationSimple: ContextValidator = ({ simulation }) =>
  simulation.structureSciAssociation === SimulationStructureSciAssociation.Simple

export const hasAge: ContextValidator = ({ simulation }) => isNotNil(simulation.profileMortgagors[0]?.age)

export const hasCoMortgagorAge: ContextValidator = ({ simulation }) => isNotNil(simulation.profileMortgagors[1]?.age)

export const hasChildrenNumber: ContextValidator = ({ simulation }) => isNotNil(simulation.profileChildren)

export const hasLiveTogetherCompleted: ContextValidator = ({ simulation }) => isNotNil(simulation.profileLiveTogether)

const hasMetropolitanAddress = (mortgagor: SimulationMortgagor) =>
  mortgagor.lodgingLocationKind === SimulationMortgagorLodgingLocationKind.FranceMetropolitan &&
  mortgagor.lodgingLocation?.countryCode === 'fr' &&
  isNotNil(mortgagor.lodgingLocation?.city) &&
  isNotNil(mortgagor.lodgingLocation?.zipcode)

const hasInternationalAddress = (mortgagor: SimulationMortgagor) =>
  mortgagor.lodgingLocationKind === SimulationMortgagorLodgingLocationKind.World &&
  isNotNil(mortgagor.lodgingLocation?.countryCode) &&
  mortgagor.lodgingLocation?.countryCode !== 'fr'

export const hasAddress: ContextValidator = context =>
  hasMortgagor(context) &&
  anyPass([hasMetropolitanAddress, hasInternationalAddress])(context.simulation.profileMortgagors[0])

export const isInternational: ContextValidator = ({ simulation }) =>
  simulation.profileMortgagors[0]?.lodgingLocationKind === SimulationMortgagorLodgingLocationKind.World

export const isNotInternational: ContextValidator = ({ simulation }) =>
  simulation.profileMortgagors[0]?.lodgingLocationKind === SimulationMortgagorLodgingLocationKind.FranceMetropolitan

export const hasComortgagorAddress: ContextValidator = context =>
  hasComortgagor(context) &&
  anyPass([hasMetropolitanAddress, hasInternationalAddress])(context.simulation.profileMortgagors[1])

export const isComortgagorInternational: ContextValidator = ({ simulation }) =>
  simulation.profileMortgagors[1]?.lodgingLocationKind === SimulationMortgagorLodgingLocationKind.World

export const isComortgagorNotInternational: ContextValidator = ({ simulation }) =>
  simulation.profileMortgagors[1]?.lodgingLocationKind === SimulationMortgagorLodgingLocationKind.FranceMetropolitan

export const isExpatriateFilled: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.profileMortgagors[0]?.occupationExpatriation)

export const isCoMortgagorExpatriateFilled: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.profileMortgagors[1]?.occupationExpatriation)

export const hasSituationType: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.profileMortgagors[0]?.lodgingSituationKind)

const shouldHaveRent = (mortgagor: SimulationMortgagor) =>
  mortgagor.lodgingSituationKind === SimulationMortgagorLodgingSituationKind.StaffHoused ||
  mortgagor.lodgingSituationKind === SimulationMortgagorLodgingSituationKind.Tenant

export const hasRent: ContextValidator = context =>
  hasMortgagor(context) && shouldHaveRent(context.simulation.profileMortgagors[0])
    ? isNotNil(context.simulation.profileMortgagors[0]?.lodgingRent)
    : true

const isOwner = (mortgagor: SimulationMortgagor) =>
  mortgagor.lodgingSituationKind === SimulationMortgagorLodgingSituationKind.Owner

export const hasForSale: ContextValidator = context =>
  hasMortgagor(context) &&
  context.simulation.propertyUsage === SimulationPropertyUsage.PrimaryResidence &&
  isOwner(context.simulation.profileMortgagors[0])
    ? isNotNil(context.simulation.ownPropertyForSale)
    : true

const isOccupationPrivate = (mortgagor: SimulationMortgagor) =>
  mortgagor.occupationKind === SimulationMortgagorOccupationKind.Private

const isExecutiveRoleFilled = (mortgagor: SimulationMortgagor) => isNotNil(mortgagor.occupationPrivateRole)

const isTrialPeriodFilled = (mortgagor: SimulationMortgagor) => isNotNil(mortgagor.occupationTrialPeriod)

const isLongTerm = (mortgagor: SimulationMortgagor) =>
  mortgagor.occupationDuration === SimulationMortgagorOccupationDuration.LongTerm

const isShortTerm = (mortgagor: SimulationMortgagor) =>
  mortgagor.occupationDuration === SimulationMortgagorOccupationDuration.ShortTerm

const isStartDateFilled = (mortgagor: SimulationMortgagor) => isNotNil(mortgagor.occupationStartYear)

const isPrivateFilled = (mortgagor: SimulationMortgagor) =>
  allPass([
    isOccupationPrivate,
    anyPass([
      allPass([isExecutiveRoleFilled, isTrialPeriodFilled, isLongTerm]),
      allPass([isShortTerm, isStartDateFilled]),
    ]),
  ])(mortgagor)

const isOccupationPublic = (mortgagor: SimulationMortgagor) =>
  mortgagor.occupationKind === SimulationMortgagorOccupationKind.Public

const isOccupationMedical = (mortgagor: SimulationMortgagor) =>
  mortgagor.occupationKind === SimulationMortgagorOccupationKind.Medical

const isTenure = (mortgagor: SimulationMortgagor) =>
  mortgagor.occupationPublicStatus === SimulationMortgagorOccupationPublicStatus.Tenure

const isIntern = (mortgagor: SimulationMortgagor) =>
  mortgagor.occupationPublicStatus === SimulationMortgagorOccupationPublicStatus.Intern

const isContractor = (mortgagor: SimulationMortgagor) =>
  mortgagor.occupationPublicStatus === SimulationMortgagorOccupationPublicStatus.Contractor

const isPublicFilled = (mortgagor: SimulationMortgagor) =>
  anyPass([
    allPass([isOccupationPublic, isTenure]),
    isIntern,
    allPass([isContractor, anyPass([isLongTerm, allPass([isShortTerm, isStartDateFilled])])]),
  ])(mortgagor)

const isOccupationOthers = (mortgagor: SimulationMortgagor) =>
  mortgagor.occupationKind === SimulationMortgagorOccupationKind.Other

const hasStatus = (mortgagor: SimulationMortgagor) => isNotNil(mortgagor.occupationOtherStatus)

const isRetired = (mortgagor: SimulationMortgagor) =>
  mortgagor.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.Retired

const isJobless = (mortgagor: SimulationMortgagor) =>
  mortgagor.occupationOtherStatus === SimulationMortgagorOccupationOtherStatus.Jobless

const hasStartDate = (mortgagor: SimulationMortgagor) =>
  isNotNil(mortgagor.occupationStartMonth) && isNotNil(mortgagor.occupationStartYear)

const isOthersFilled = (mortgagor: SimulationMortgagor) =>
  allPass([isOccupationOthers, hasStatus, anyPass([isRetired, isJobless, hasStartDate])])(mortgagor)

const isMedicalFilled = (mortgagor: SimulationMortgagor) => allPass([isOccupationMedical, hasStartDate])(mortgagor)

export const isOccupationFilled: ContextValidator = context =>
  hasMortgagor(context) &&
  anyPass([isPrivateFilled, isPublicFilled, isOthersFilled, isMedicalFilled])(context.simulation.profileMortgagors[0])

export const isCoMortgagorOccupationFilled: ContextValidator = context =>
  hasComortgagor(context) &&
  anyPass([isPrivateFilled, isPublicFilled, isOthersFilled, isMedicalFilled])(context.simulation.profileMortgagors[1])

export const isNotOthersSector: ContextValidator = ({ simulation }) =>
  simulation.profileMortgagors[0]?.occupationKind !== SimulationMortgagorOccupationKind.Other

export const isOthersSector: ContextValidator = ({ simulation }) =>
  simulation.profileMortgagors[0]?.occupationKind === SimulationMortgagorOccupationKind.Other

export const isCoMortgagorOthersSector: ContextValidator = ({ simulation }) =>
  simulation.profileMortgagors[1]?.occupationKind === SimulationMortgagorOccupationKind.Other

export const isCoMortgagorNotOthersSector: ContextValidator = ({ simulation }) =>
  simulation.profileMortgagors[1]?.occupationKind !== SimulationMortgagorOccupationKind.Other

export const isSalaryFilled: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.profileMortgagors[0]?.incomeSalaryAmount) &&
  isNotNil(simulation.profileMortgagors[0]?.incomeSalaryPeriod) &&
  isNotNil(simulation.profileMortgagors[0]?.incomeSalaryTaxes)

export const isCoMortgagorSalaryFilled: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.profileMortgagors[1]?.incomeSalaryAmount) &&
  isNotNil(simulation.profileMortgagors[1]?.incomeSalaryPeriod) &&
  isNotNil(simulation.profileMortgagors[1]?.incomeSalaryTaxes)

export const isBonusFilled: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.profileMortgagors[0]?.incomeHasBonus) &&
  (simulation.profileMortgagors[0]?.incomeHasBonus === false ||
    (simulation.profileMortgagors[0]?.incomeHasBonus === true &&
      isNotNil(simulation.profileMortgagors[0]?.incomeBonusAmount) &&
      isNotNil(simulation.profileMortgagors[0]?.incomeBonusPeriod) &&
      isNotNil(simulation.profileMortgagors[0]?.incomeBonusTaxes)))

export const isCoMortgagorBonusFilled: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.profileMortgagors[1]?.incomeHasBonus) &&
  (simulation.profileMortgagors[1]?.incomeHasBonus === false ||
    (simulation.profileMortgagors[1]?.incomeHasBonus === true &&
      isNotNil(simulation.profileMortgagors[1]?.incomeBonusAmount) &&
      isNotNil(simulation.profileMortgagors[1]?.incomeBonusPeriod) &&
      isNotNil(simulation.profileMortgagors[1]?.incomeBonusTaxes)))

export const doesNotLiveTogether: ContextValidator = ({ simulation }) => simulation.profileLiveTogether === false

export const doesLiveTogether: ContextValidator = ({ simulation }) => simulation.profileLiveTogether === true

export const isRevenueFilled: ContextValidator = ({ simulation }) =>
  Array.isArray(simulation.profileMortgagors[0]?.incomeAdditionalRevenues)

export const isCoMortgagorRevenueFilled: ContextValidator = ({ simulation }) =>
  Array.isArray(simulation.profileMortgagors[1]?.incomeAdditionalRevenues)

export const hasComortgagorSituationType: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.profileMortgagors[1]?.lodgingSituationKind)

export const hasComortgagorRent: ContextValidator = context =>
  hasComortgagor(context) && shouldHaveRent(context.simulation.profileMortgagors[1])
    ? isNotNil(context.simulation.profileMortgagors[1]?.lodgingRent)
    : true

export const hasComortgagorForSale: ContextValidator = context =>
  hasComortgagor(context) &&
  context.simulation.propertyUsage === SimulationPropertyUsage.PrimaryResidence &&
  isOwner(context.simulation.profileMortgagors[1])
    ? isNotNil(context.simulation.ownPropertyForSale)
    : true

export const hasAnyForSale: ContextValidator = context => anyPass([hasForSale, hasComortgagorForSale])(context)

export const hasOneMedical: ContextValidator = context =>
  (hasMortgagor(context) && isOccupationMedical(context.simulation.profileMortgagors[0])) ||
  (hasComortgagor(context) && isOccupationMedical(context.simulation.profileMortgagors[1]))

export const isForSale: ContextValidator = ({ simulation }) =>
  simulation.propertyUsage === SimulationPropertyUsage.PrimaryResidence &&
  !!simulation.ownPropertyForSale &&
  (simulation.profileMortgagors[0]?.lodgingSituationKind === SimulationMortgagorLodgingSituationKind.Owner ||
    simulation.profileMortgagors[1]?.lodgingSituationKind === SimulationMortgagorLodgingSituationKind.Owner)

export const isNotForSale: ContextValidator = ({ simulation }) =>
  !simulation.ownPropertyForSale ||
  (simulation.profileMortgagors[0]?.lodgingSituationKind !== SimulationMortgagorLodgingSituationKind.Owner &&
    simulation.profileMortgagors[1]?.lodgingSituationKind !== SimulationMortgagorLodgingSituationKind.Owner)

export const isPrimaryUsage: ContextValidator = ({ simulation }) =>
  simulation.propertyUsage === SimulationPropertyUsage.PrimaryResidence

export const isNotPrimaryUsage: ContextValidator = ({ simulation }) =>
  simulation.propertyUsage !== SimulationPropertyUsage.PrimaryResidence

export const hasEstimatedPrice: ContextValidator = ({ simulation }) => isNotNil(simulation.ownPropertyEstimatedPrice)

export const hasBank: ContextValidator = ({ simulation }) => isNotNil(simulation.renegotiationLoanBank)

export const hasInsuranceType: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.renegotiationLoanInsuranceKind)

export const isBankGroupInsurance: ContextValidator = ({ simulation }) =>
  simulation.renegotiationLoanInsuranceKind === SimulationRenegotiationLoanInsuranceKind.Group

export const isDelegatedInsurance: ContextValidator = ({ simulation }) =>
  simulation.renegotiationLoanInsuranceKind === SimulationRenegotiationLoanInsuranceKind.Delegate

export const hasRenegotiationPayment: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.renegotiationLoanPayment)

export const hasRenegotiationInsurancePayment: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.renegotiationLoanInsurancePayment)

export const hasRate: ContextValidator = ({ simulation }) => isNotNil(simulation.renegotiationLoanRate)

export const hasStartYear: ContextValidator = ({ simulation }) => isNotNil(simulation.renegotiationLoanStartYear)

export const hasRenegotiationDuration: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.renegotiationLoanDuration)

export const hasRenegotiationStartYear: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.renegotiationLoanStartYear)

export const hasRenegotiationPropertyEstimatedPrice: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.renegotiationPropertyEstimatedPrice)

export const isPaidOff: ContextValidator = ({ simulation }) => simulation.ownPropertyPaidOff === true

export const isNotPaidOff: ContextValidator = ({ simulation }) => simulation.ownPropertyPaidOff === false

export const hasRemainingMortgage: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.ownPropertyRemainingPrincipal)

export const isBuyoutFilled: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.buyoutAmount) &&
  (simulation.buyoutHasCredit === false ||
    (simulation.buyoutHasCredit === true && isNotNil(simulation.buyoutCreditRemainingPrincipal)))

export const isCreditsFilled: ContextValidator = ({ simulation }) => Array.isArray(simulation.chargesCredits)

export const isChildSupportFilled: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.profileMortgagors[0]?.chargesPaysChildSupport)

export const isCoMortgagorChildSupportFilled: ContextValidator = ({ simulation }) =>
  isNotNil(simulation.profileMortgagors[1]?.chargesPaysChildSupport)

export const hasGood: ContextValidator = ({ simulation }) =>
  simulation.propertyKind === SimulationPropertyKind.Old ||
  simulation.propertyKind === SimulationPropertyKind.New ||
  simulation.propertyKind === SimulationPropertyKind.Vefa

export const hasPropertyPrice: ContextValidator = ({ simulation }) => isNotNil(simulation.propertyPrice)

export const isPropertyOld: ContextValidator = ({ simulation }) =>
  simulation.propertyKind === SimulationPropertyKind.Old

export const isPropertyNew: ContextValidator = ({ simulation }) =>
  simulation.propertyKind === SimulationPropertyKind.New

export const isPropertyVEFA: ContextValidator = ({ simulation }) =>
  simulation.propertyKind === SimulationPropertyKind.Vefa

export const isPropertyNewOrVEFA: ContextValidator = ({ simulation }) =>
  simulation.propertyKind === SimulationPropertyKind.New || simulation.propertyKind === SimulationPropertyKind.Vefa

export const isOldNewOrVEFA: ContextValidator = ({ simulation }) =>
  simulation.propertyKind === SimulationPropertyKind.Old ||
  simulation.propertyKind === SimulationPropertyKind.New ||
  simulation.propertyKind === SimulationPropertyKind.Vefa

export const isPropertyConstruction: ContextValidator = ({ simulation }) =>
  simulation.propertyKind === SimulationPropertyKind.Construction

export const isPropertyLandConstruction: ContextValidator = ({ simulation }) =>
  simulation.propertyKind === SimulationPropertyKind.LandConstruction

export const hasWorks: ContextValidator = ({ simulation }) => simulation.propertyHasWorks === true

export const hasNoWorks: ContextValidator = ({ simulation }) => simulation.propertyHasWorks === false

export const hasWorksPrice: ContextValidator = ({ simulation }) => isNotNil(simulation.propertyWorksPrice)

export const isWorksFilled: ContextValidator = context =>
  (hasWorks(context) && hasWorksPrice(context)) || hasNoWorks(context)

export const hasConstructionPrice: ContextValidator = ({ simulation }) => isNotNil(simulation.propertyConstructionPrice)

export const hasLandPrice: ContextValidator = ({ simulation }) => isNotNil(simulation.propertyLandPrice)

export const isCapacity: ContextValidator = ({ simulation }) => simulation.kind === SimulationKind.Capacity

export const isPurchase: ContextValidator = ({ simulation }) => simulation.kind === SimulationKind.Purchase

const isExcludedZipCode = (excludedPtzZipcodes: string[], zipcode: string): boolean =>
  !excludedPtzZipcodes.find(code => code === zipcode)

const isSpecificPtzOld: ContextValidator = ({ simulation }) => {
  const price = (simulation.propertyPrice || 0) + (simulation.propertyWorksPrice || 0)

  return (
    simulation.propertyKind === SimulationPropertyKind.Old &&
    isExcludedZipCode(excludedPtzZipCodes, simulation.propertyLocation?.zipcode || '') &&
    !!simulation.propertyHasWorks &&
    !!simulation.propertyWorksPrice &&
    simulation.propertyWorksPrice >= price * 0.25
  )
}

export const hasPTZ: ContextValidator = context =>
  (context.simulation.propertyKind !== SimulationPropertyKind.Old || isSpecificPtzOld(context)) &&
  context.simulation.propertyUsage === SimulationPropertyUsage.PrimaryResidence &&
  context.simulation.profileMortgagors[0]?.lodgingSituationKind !== SimulationMortgagorLodgingSituationKind.Owner

export const hasNotPTZ: ContextValidator = context => !hasPTZ(context)

export const isEligibleToEcoPtz: ContextValidator = context =>
  context.simulation.propertyKind === SimulationPropertyKind.Old &&
  // Zou only accepts EcoPtz for primary residence
  context.simulation.propertyUsage ===
    SimulationPropertyUsage.PrimaryResidence /** || simulation.usage === Usage.Rental**/ &&
  hasWorks(context)

export const isNotEligibleToEcoPtz: ContextValidator = context => !isEligibleToEcoPtz(context)

export const isEcoPtzFilled: ContextValidator = ({ simulation }) => isNotNil(simulation.propertyHasRenovationWorks)

export const hasBeenOwner: ContextValidator = ({ simulation }) => simulation.ptzBeenOwner === true

export const hasNotBeenOwner: ContextValidator = ({ simulation }) => simulation.ptzBeenOwner === false

export const hasFiscalIncomeNM2: ContextValidator = ({ simulation }) => isNotNil(simulation.ptzFiscalIncomeNm2)

export const isContributionFilled: ContextValidator = ({ simulation }) => isNotNil(simulation.contribution)

export const isSavingsFilled: ContextValidator = ({ simulation }) => isNotNil(simulation.savingsResidual)

export const isRateAlertFilled: ContextValidator = ({ rateAlert }) =>
  rateAlert.wantsRateAlert === false || (rateAlert.wantsRateAlert === true && isNotNil(rateAlert.email))

export const isVisibleRateAlert: ContextValidator = ({ rateAlert }) => rateAlert.isVisible === true

export const isNotVisibleRateAlert: ContextValidator = ({ rateAlert }) => rateAlert.isVisible === false

export const isPrettoSearchCriteriaEmailFilled: ContextValidator = ({ prettoSearch }) =>
  isNotNil(prettoSearch.areaMax) && isNotNil(prettoSearch.areaMin) && isNotNil(prettoSearch.email)

export const isVisiblePrettoSearch: ContextValidator = ({ prettoSearch }) => prettoSearch.isVisible === true

export const isNotVisiblePrettoSearch: ContextValidator = ({ prettoSearch }) => prettoSearch.isVisible === false

export const isPrettoSearchLocationsFilled: ContextValidator = ({ prettoSearch }) =>
  prettoSearch.locations.length > 0 &&
  prettoSearch.locations.every(location => location.city !== null && location.zipcode !== null)

export const isPrettoSearchFrequencyNever: ContextValidator = ({ prettoSearch }) =>
  prettoSearch.frequency === Frequency.Never

export const isNotPrettoSearchFrequencyNever: ContextValidator = ({ prettoSearch }) =>
  prettoSearch.frequency !== Frequency.Never

export const isPrettoSearchFrequencyFilled: ContextValidator = ({ prettoSearch }) => isNotNil(prettoSearch.frequency)

export const hasCompetition: ContextValidator = ({ simulation }) => simulation.competitionHasOffer === true

export const isCompetitionFilled: ContextValidator = ({ simulation }) => isNotNil(simulation.competitionHasOffer)

export const hasNotCompetition: ContextValidator = ({ simulation }) => simulation.competitionHasOffer === false

export const hasCompetitionBank: ContextValidator = ({ simulation }) => isNotNil(simulation.competitionBank)

export const hasCompetitionRate: ContextValidator = ({ simulation }) => isNotNil(simulation.competitionRate)

export const hasCompetitionDuration: ContextValidator = ({ simulation }) => isNotNil(simulation.competitionDuration)

export const hasDpe: ContextValidator = ({ simulation }) => isNotNil(simulation.propertyDpe)

export const isPartner: ContextValidator = () => isProjectChoicePartner(getUtmFlagsLastClicked().utm_campaign)

export const isNotPartner: ContextValidator = () => !isProjectChoicePartner(getUtmFlagsLastClicked().utm_campaign)
