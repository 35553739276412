import sentencesTranslations from '@pretto/app/src/Sentences/config/translations/fr'

/* eslint-disable max-lines */
const translation = {
  ...sentencesTranslations,
  navigation: {
    login: 'Se connecter',
    dashboard: 'Tableau de bord',
    documents: 'Documents',
    folder: 'Dossier',
    modifySimulation: 'Modifier ma simulation',
    simulator: 'Simulation',
    capacity: 'Budget',
    result: 'Résultat',
    goBack: 'Retour',
  },
  languageSwitchDialog: {
    title: '🇬🇧 Do you prefer English?',
    message:
      'Pretto can offer a full service in English. Complete the simulation and connect with one of our English-speaking brokers to bring your project to life.',
    button: 'Go to the English version',
    close: 'Close',
  },
  prettoSearchHeader: {
    message:
      'Trouvez facilement votre futur bien. <Strong>Pretto Search</Strong> recherche les annonces immo pour vous !',
  },
  pushy: {
    title: 'Sauvegardez votre progression !',
    agreement: 'J’accepte que Pretto et ses partenaires de confiance me contactent pour étudier mon projet immobilier.',
    disclaimer:
      'Pas d’inquiétude ! Vos <strong>données personnelles sont protégées</strong> et utilisées seulement pour votre recherche de prêt.',
    continue: 'Continuer',
    cgu: {
      acceptTerms:
        'En continuant vous créez un compte Pretto et acceptez nos <CguLink>conditions d’utilisation</CguLink> et notre <PrivacyLink>politique de confidentialité</PrivacyLink>.',
    },
    placeholder: {
      firstName: 'Prénom',
      lastName: 'Nom',
      email: 'Email',
      phoneNumber: 'Votre n° de téléphone',
    },
    skip: 'Passer cette étape',
  },
  mutualizedAgenda: {
    tag: 'Votre rendez-vous',
    title: {
      postPushy: 'Empruntez bien accompagné',
      default: `Entretenez-vous avec un {{formatedAdvisorLabel}}`,
    },
    duration: '(30 min)',
    description: {
      postPushy: 'Choisissez un rendez-vous dans l’agenda de nos {{formatedAdvisorLabel}}s Pretto.',
      hot: 'Il analyse gratuitement votre projet immo et vous présente les différentes <strong>offres</strong> bancaires.',
      default: 'Ce RDV est la première étape pour obtenir votre <strong>attestation de financement</strong> Pretto.',
    },
    appointmentDuration: 'Durée du rendez-vous : 30 minutes',
    appointmentSlots: 'Aucun créneau ne vous convient ?',
    buttonDRI: 'Rappelez-moi dès que possible',
    buttonSkip: {
      postPushy: 'Continuer sans prendre RDV',
      default: 'Accéder à mon Espace Client',
    },
    localTime: 'Heure locale : {{timezone}}',
    subscribe: {
      confirmation:
        'Votre rendez-vous aura lieu le {{appointmentDay}} <strong>{{appointmentDate}}</strong> à <strong>{{appointmentTime}}</strong> avec votre {{advisorLabel}} <strong>{{advisorName}}</strong>.',
      edit: 'Modifier le RDV',
      textChooseVisio:
        'Bonne nouvelle ! Pour bénéficier d’une expérience plus humaine, votre {{advisorLabel}} <strong>{{advisorName}}</strong> vous propose la <strong>visio</strong>.',
      chooseVisio: 'Je choisis l’option visio !',
      choosePhone: 'Je préfère passer par téléphone',
      confirm: 'Confirmer le RDV',
    },
    videoCallAvailable: 'RDV visio disponible',
    noSlotsErrorMessage:
      'L’agenda est indisponible pour le moment. Cliquez sur <strong>Continuer sans prendre rdv</strong>, vous aurez la possibilité de choisir un créneau de rendez-vous directement depuis votre Espace Client.',
  },
  reduceAmbitionPage: {
    title: 'Donnons nous toutes les chances d’y arriver !',
    description:
      'Chez Pretto, nos courtiers font leur maximum pour assurer le financement de votre projet. Mais en l’état, votre apport est trop faible pour le montant de votre bien. Nous vous invitons donc à ajuster votre projet. Vous pouvez également simuler votre <CapacityLink>capacité maximale d’achat</CapacityLink>.',
    tweakProject: 'Ajuster votre projet :',
    resultsButton: 'Résultats',
    bumpContribution: {
      label: "Augmenter l'apport",
      labelShort: 'Apport',
      tooltip:
        'Exigé par les banques pour financer les frais de notaire et de garantie, l’apport doit généralement représenter au minimum <strong>10% du prix du bien</strong> (sauf cas particuliers).',
    },
    downsizeProject: {
      label: 'Réduire le projet',
      labelShort: 'Projet',
      tooltip:
        'Si vous n’êtes pas encore engagé auprès de votre vendeur, nous vous conseillons de <strong>faire une offre</strong> plus basse que le prix de vente.',
    },
    optionsPage: {
      edit: 'Modifier',
      editProfile: 'Modifier votre profil',
      editFolder: 'Modifier votre dossier',
      message:
        'Il se peut que les informations de votre profil soient erronées. Vous pouvez les modifier si nécessaire.',
    },
  },
  simulation: {
    loader: 'Nous évaluons plus de 1 500 combinaisons de prêt pour votre profil…',
    editor: {
      title: 'Votre simulation',
      propertyPrice: 'Prix du bien',
      landPrice: 'Prix du terrain',
      buildPrice: 'Prix de la construction',
      worksPrice: 'Prix des travaux',
      contribution: 'Apport',
      remainingSavings: 'Epargne restante',
      edit: 'Modifier',
    },
    appointmentConfirmed: {
      title: 'Votre rendez-vous est confirmé !',
      text: '{{name}}, votre expert, vous appellera le <Date>{{start}}</Date>. D’ici là, préparez votre rendez-vous dans votre espace client.',
      cta: 'Accéder à mon espace client',
    },
  },
  noFinancingPage: {
    sorry: 'Nous sommes désolés...',
    noRealEstateOffer: 'Nous n’avons trouvé aucune offre de financement immobilier correspondant actuellement.',
    noFinancingOffer: 'Nous n’avons trouvé aucune offre de financement correspondant à votre situation actuelle.',
    reasonsMessageShort_one: 'Voici la raison qui peut expliquer pourquoi :',
    reasonsMessageShort_other: 'Voici les raisons qui peuvent expliquer pourquoi :',
    reasonsMessageLong_one: 'Voici la raison qui peut expliquer pourquoi Pretto ne peut pas vous accompagner :',
    reasonsMessageLong_other: 'Voici les raisons qui peuvent expliquer pourquoi Pretto ne peut pas vous accompagner :',
    restoreMessage:
      'Vous pouvez restaurer votre projet enregistré avec les paramètres que vous aviez définis. Votre projet enregistré est finançable.',
    restoreCta: 'Restaurer',
    blocking: 'bloquant',
    warning: 'attention',
    moreInfo: 'En savoir plus',
    comments: {
      noIncome: 'Aucun revenu pris en compte.',
      chargesAccount:
        'Charges prises en compte :\nfuture mensualité : {{financing.payment}} €, loyer : {{project.facts.detailed_charges.rent}} €, crédits en cours : {{project.facts.detailed_charges.credits}} €, pension alimentaire : {{project.facts.detailed_charges.child_support}} €, autres charges : {{project.facts.detailed_charges.other_charges}} €',
      incomeAccount:
        'Revenus pris en compte :\n{{mortgagorIncomes}}, loyers perçus : {{financing.monthlyDetailedWeightedIncomes.rental_income}} €, loyers anticipés : {{financing.monthlyDetailedWeightedIncomes.expected_rental_income}} €, autres : {{financing.monthlyDetailedWeightedIncomes.other}} €',
      indebtednessAfterBridge:
        "Taux d'endettement = (Charges actuelles + Nouvelle mensualité après relais) / Revenus pris en compte\n\nCharges actuelles = {{totalCharges}} €\nNouvelle mensualité après relais = {{financing.paymentAfterBridge}} €\nRevenus pris en compte = {{totalIncomes}} €",
      indebtednessDifferential:
        "Taux d'endettement différentiel = (Charges actuelles + Nouvelle mensualité - Revenus locatifs) / (Revenus pris en compte - revenus locatifs)\n\nCharges actuelles = {{totalCharges}} €\nNouvelle mensualité = {{financing.payment}} €\nRevenus locatifs = {{rentIncomes}} €\nRevenus pris en compte = {{totalIncomes}} €",
      indebtednessStandard:
        "Taux d'endettement = (Charges actuelles + Nouvelle mensualité) / Revenus pris en compte\n\nCharges actuelles = {{totalCharges}} €\nNouvelle mensualité = {{financing.payment}} €\nRevenus pris en compte = {{totalIncomes}} €",
      mortgagorIncomeSingle: 'Rémunération emprunteur : {{financing.monthlyDetailedWeightedIncomes.wages[0]}} €',
      mortgagorIncomeJoint:
        'Rémunération emprunteur : {{financing.monthlyDetailedWeightedIncomes.wages[0]}} €, rémunération coemprunteur : {{financing.monthlyDetailedWeightedIncomes.wages[1]}} €',
      contracts: {
        contractJoinWord: 'et',
        contractPrefixWordShort: "qu'",
        contractPrefixWordLong: 'que',
        seniority: {
          lessThanOneYear: "moins d'un an",
          year: 'an',
          years: 'ans',
        },
        portage_salarial: 'le portage salarial',
        fixed_term: 'CDD',
        jobless: 'sans profession',
        parental_leave: 'en congé parental',
        permanent_executive: 'CDI cadre',
        permanent_executive_trial: "CDI cadre en période d'essai",
        permanent_non_executive: 'CDI non-cadre',
        permanent_non_executive_trial: "CDI non-cadre en période d'essai",
        business_owner_one: "chef d'entreprise",
        business_owner_other: "chefs d'entreprise",
        casual_show_business_worker_one: 'intermittent du spectacle',
        casual_show_business_worker_other: 'intermittents du spectacle',
        contractuel_cdd_one: 'contractuel en CDD',
        contractuel_cdd_other: 'contractuels en CDD',
        contractuel_cdi_one: 'contractuel en CDI',
        contractuel_cdi_other: 'contractuels en CDI',
        craftsman_one: 'artisan',
        craftsman_other: 'artisans',
        doctor_one: 'médecin',
        doctor_other: 'médecins',
        farmer_one: 'agriculteur',
        farmer_other: 'agriculteurs',
        interim_one: 'intérimaire',
        interim_other: 'intérimaires',
        internship_one: 'stagiaire',
        internship_other: 'stagiaires',
        jobseeker_one: "chercheur d'emploi",
        jobseeker_other: "chercheurs d'emploi",
        lawyer_one: 'avocat',
        lawyer_other: 'avocats',
        liberal_one: 'profession libérale hors médecin / avocat',
        liberal_other: 'professions libérales hors médecins / avocats',
        retired_one: 'retraité',
        retired_other: 'retraités',
        salaried_director_one: 'gérant salarié',
        salaried_director_other: 'gérants salariés',
        self_employed_one: 'travailleur indépendant ou auto-entrepreneur',
        self_employed_other: 'travailleurs indépendants ou auto-entrepreneurs',
        student_one: 'étudiant',
        student_other: 'étudiants',
        tenure_one: 'titulaire',
        tenure_other: 'titulaires',
        trader_one: 'commerçant',
        trader_other: 'commerçants',
        military_one: 'militaire',
        military_other: 'militaires',
        childminder_one: 'assistant maternel',
        childminder_other: 'assistants maternels',
      },
    },
  },
  arrangeCreditsPage: {
    repayWithContribution_one: 'Pour réaliser ce projet, remboursez le crédit suivant à l’aide de votre apport :',
    repayWithContribution_other: 'Pour réaliser ce projet, remboursez les crédits suivants à l’aide de votre apport :',
    repayedLoans_one: 'Crédit remboursé',
    repayedLoans_other: 'Crédits remboursés',
    amountPerMonthSuffix: '€ / mois',
    amountSuffix: '€',
    newContribution: 'Nouvel apport',
    repayLoansTitle: 'Remboursez vos crédits',
    consolidateDebt_one: 'Pour réaliser ce projet, faites racheter le crédit suivant par la banque :',
    consolidateDebt_other: 'Pour réaliser ce projet, faites racheter les crédits suivants par la banque :',
    consolidatedLoans_one: 'Crédit racheté',
    consolidatedLoans_other: 'Crédits rachetés',
    consolidateDebtTitle: 'Faites racheter vos crédits',
    adjustProject: 'Ajustez votre demande',
    adjustProjectMessage:
      'Nous vous conseillons d’ajuster les paramètres de votre projet pour réduire votre endettement.',
  },
  nonFinancingSignupPage: {
    title: 'Votre projet retient toute notre attention',
    message:
      'Votre situation mérite de parler directement avec un courtier. Prenez rendez-vous avec l’un de nos <strong>experts en crédit spécialisés</strong> pour votre profil et trouvez la meilleure offre de financement.',
    free: 'Gratuit',
    noCommitment: 'Sans engagement',
    bookAppointment: 'Prenons rendez-vous !',
  },
  form: {
    mandatoryField: 'Ce champ est obligatoire',
    invalidEmail: 'Votre adresse email n’est pas valide',
    invalidPhone: 'Ce n° de téléphone n’est pas valide',
    rectifyEmail: 'Vouliez-vous dire {{suggestion}} ? <CorrectButton>Corriger</CorrectButton>',
  },
  signupForm: {
    defaultTitle: 'On fait les présentations ?',
    contactInfo: 'Vos coordonnées :',
    placeholders: {
      email: 'Votre adresse email',
      firstName: 'Prénom',
      lastName: 'Nom',
      phone: 'Votre numéro de téléphone',
    },
    maturity: {
      placeholder: 'Avancement de mon projet',
      underStudy: 'Je suis en réflexion',
      searchStarted: 'Je suis en recherche active de bien',
      offerMade: "J’ai fait une offre d'achat pour un bien",
      offerAccepted: 'J’ai une offre d’achat acceptée',
      saleAgreementSigned: 'J’ai signé un compromis de vente',
    },
    acceptTerms:
      'J’accepte que Pretto et ses partenaires de confiance me contactent pour étudier mon projet immobilier, ainsi que les <CguLink>conditions d’utilisation</CguLink> et la <PrivacyLink>politique de confidentialité</PrivacyLink> de Pretto.',
    accountExistsError: 'Un compte existe déjà avec cette adresse email. Vérifiez que vous l’avez bien saisie.',
    error: 'Une erreur est survenue, veuillez réessayer ou contacter le support client.',
    mandatoryFieldBooking: 'Cette case est obligatoire pour prendre rendez-vous',
    bookAppointmentCTA: 'Je prends rendez-vous',
  },
  faqCard: {
    question: 'Une question ?',
    title: 'Consultez la FAQ',
    description: 'Tous les conseils, questions et réponses de l’équipe Pretto.',
    goToFaq: 'Consulter',
  },
  guideCard: {
    discover: 'Découvrez...',
    title: 'Le guide de l’acheteur',
    description:
      'Soyez prêt pour votre premier achat immobilier. Tous nos conseils sont réunis dans un ebook gratuit !',
    download: 'Télécharger',
    imgAlt: 'Couverture du guide de l’acheteur',
  },
}

export default translation
